<template>
  <div class="list" id="layoutid" :class="{ hasnews: news && news.isShow }">
    <div class="head">
      <div class="logo dis_flex_center"><img src="~@/assets/images/eyelogo2.png" alt="" /></div>
      <div class="head-r">
        <ul class="dis_flex">
          <NewsIcon radioTheme="0" />
          <li>
            <el-dropdown>
              <!-- <span class="el-dropdown-link"> 基础管理<i class="el-icon-arrow-down el-icon--right"></i> </span> -->
              <span class="el-dropdown-link"><i class="iconfont iconguanli"></i> </span>
              <el-dropdown-menu class="listdropdown" slot="dropdown">
                <el-dropdown-item><a :href="bigmanageurl1" target="_blank">用户管理</a></el-dropdown-item>
                <el-dropdown-item><a :href="bigmanageurl2" target="_blank">角色管理</a></el-dropdown-item>
                <el-dropdown-item><a :href="bigmanageurl3" target="_blank">下载中心</a></el-dropdown-item>
                <el-dropdown-item v-if="$EnvIsMallcoo"><a :href="bigmanageurl4" target="_blank">预警中心</a></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <!-- <li><a href="http://mallcoobiimg.oss-cn-shanghai.aliyuncs.com/用户行为数据统计分析系统使用说明书.pdf" target="_blank">帮助文档</a></li>
          <li>English</li> -->
          <li class="user ">
            <el-dropdown trigger="click">
              <div class="el-dropdown-link dis_flex">
                <span v-if="userInfo">{{ userInfo.name }}</span
                ><i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <el-dropdown-menu class="listdropdown" slot="dropdown">
                <el-dropdown-item><a :href="changepwd" target="_blank">修改密码</a></el-dropdown-item>
                <el-dropdown-item @click.native="signout"><a>退出登录</a></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
    </div>
    <news class="news_x" v-if="projectList" system="bi"></news>
    <div v-if="projectList" class="list-table" :class="{ hasnews: news && news.isShow && news.detailUrl }">
      <div class="table-box">
        <div class="table-top">
          <el-input
            class="filter"
            v-model="keywords"
            prefix-icon="el-icon-search"
            size="small"
            clearable
            @input="onInput"
            placeholder="请输入关键字"
          ></el-input>
        </div>
        <el-table v-if="projectList" :data="projectList" @sort-change="onSort" border size="small">
          <el-table-column prop="projectID" sortable label="项目ID" width="150"></el-table-column>
          <el-table-column sortable prop="name" label="项目名称">
            <template slot-scope="scope">
              <router-link :to="scope.row.href" target="_blank"
                ><el-button type="text">{{ scope.row.name }}</el-button></router-link
              >
            </template>
          </el-table-column>
          <el-table-column label="绑定商场ID" prop="malls" sortable />
          <el-table-column prop="createDate" sortable v-if="!$EnvIsMallcoo" label="创建日期" width="200" />
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <router-link :to="scope.row.href" target="_blank"><el-button type="text">访问</el-button></router-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="paging.index"
          :page-sizes="paging.sizes"
          :page-size="paging.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="paging.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { getProjects } from '@/api/bimApi'
import { getUserInfo } from '@/api/apiV2_dashboard'
import news from '@/components/news.vue'
import NewsIcon from '@/components/newsIcon.vue'
import { removeToken } from '@/utils/auth'
import BI_config from '@/utils/config'

export default {
  name: 'list',
  data() {
    return {
      userInfo: null,
      changepwd: BI_config.changePwd,
      bigmanageurl1: BI_config.bigmanage + BI_config.manageProxy + '/manage/permissions/users',
      bigmanageurl2: BI_config.bigmanage + BI_config.manageProxy + '/manage/permissions/roles',
      bigmanageurl3: BI_config.bigmanage + BI_config.manageProxy + '/manage/downCenter',
      bigmanageurl4: BI_config.bigmanage + '/MarketingPlan/NoticeList',

      paging: {
        sizes: [20, 40, 60],
        size: 0,
        index: 1,
        total: 0,
      },
      timer: null,
      projectList: null,
      sorting: { name: 'projectID', hint: 'asc' },
      keywords: '',
    }
  },
  components: {
    news,
    NewsIcon,
  },
  methods: {
    signout() {
      removeToken()
      location.reload()
    },

    onInput() {
      if (this.timer != null) clearTimeout(this.timer)
      let self = this
      this.timer = setTimeout(() => {
        self.onSearch()
      }, 1000)
    },

    onSearch() {
      this.paging.index = 1
      this.getProjectList()
    },

    onSort(column) {
      if (column.order) {
        this.sorting = { name: column.prop, hint: column.order == 'ascending' ? 'asc' : 'desc' }
      } else {
        this.sorting = null
      }
      this.onSearch()
    },

    async getProjectList() {
      let param = {
        keyword: this.keywords && this.keywords.trim(),
        projectStatus: this.currentStatus || null,
        paging: {
          index: this.paging.index - 1,
          size: this.paging.size,
        },
      }
      if (this.$EnvIsMallcoo) {
        param.projectStatus = null
      }
      if (this.sorting && this.sorting.hint && this.sorting.name) {
        let { hint, name } = this.sorting || {}
        param.sortings = [
          {
            hint: hint,
            name: this.initialToUpperCase(name),
          },
        ]
      }
      try {
        let res = await getProjects(param)
        let projectList = res.data.list
        projectList.forEach(x => {
          if (x.malls) x.malls = x.malls.join('，')
          if (x.version == '2.0' || this.$TenantMode == 'TKL') {
            x.href = `${BI_config.dashboardProxy}/Index/${x.projectID}`
          } else {
            x.href = `/Lead/Visit/${x.projectID}`
          }
        })
        this.projectList = projectList
        this.paging.total = res.data.paging.availCnt
      } catch (error) {}
    },

    initialToUpperCase(str) {
      if (!str) return null
      let r = str.split('')
      r[0] = r[0].toUpperCase()
      return r.join('')
    },

    handleSizeChange(size) {
      this.paging.size = size
      this.paging.index = 1
      this.getProjectList()
    },

    handleCurrentChange(index) {
      this.paging.index = index
      this.getProjectList()
    },
  },
  async created() {
    this.paging.size = this.paging.sizes[0]
    this.$loading({ lock: true, spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' })
    getUserInfo().then(v => {
      this.userInfo = v.data
    })
    await this.getProjectList()
    this.$loading({ lock: false }).close()
  },
  computed: {
    ...mapState({
      news: state => state.news,
    }),
  },
  mounted() {},
}
</script>

<style lang="scss">
.list {
  min-height: 100%;
  background: #f1f4fa;
  padding-top: 74px;
  &.hasnews {
    padding-top: 127px;
  }
  .head {
    width: 100%;
    height: 60px;
    background: #fff;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.12);
    position: fixed;
    z-index: 99;
    top: 0;
    .logo {
      float: left;
      width: 250px;
      height: 60px;
      img {
        width: 133px;
      }
    }
    .head-r {
      float: right;
      height: 60px;
      padding-right: 15px;
      ul {
        height: 100%;
        li {
          margin: 0 10px;
          font-size: 14px;
          cursor: pointer;
          &.user {
            img {
              width: 24px;
              height: 24px;
              border-radius: 12px;
              background: #a6dbff;
              margin-right: 5px;
            }
          }
          .el-dropdown {
          }
          > a {
            color: #333;
          }
          .iconfont {
            font-size: 18px;
          }
        }
      }
    }
  }
  .list-table {
    &.hasnews {
      top: 127px;
      position: absolute;
      height: calc(100% - 127px);

      transition: 0.3s ease-in-out;
    }
    width: calc(100% - 24px);
    top: 74px;
    position: absolute;
    height: calc(100% - 74px);
    overflow-y: auto;
    background: #ffff;
    margin: 0 15px;
    padding: 15px;
    .table-top {
      text-align: right;
      .el-select {
        margin-right: 15px;
      }
      .filter {
        width: 200px;
        margin-bottom: 10px;
      }
    }

    .table-box {
      overflow-y: auto;
      .el-table {
        color: #333;
        .el-button {
          padding: 0;
        }
        .el-table__header {
          tr {
            th {
              color: #333;
            }
          }
        }
        .allmallIds {
          span {
            white-space: nowrap;
          }
        }
      }
      .el-pagination {
        margin-top: 10px;
      }
    }
  }
  .news_x {
    width: calc(100% - 30px);
  }
}

.el-dropdown-menu.listdropdown {
  .el-dropdown-menu__item {
    background: none;
    color: #333;
    &:hover {
      background: none;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      color: #333;
      &:hover,
      &:focus {
        color: #70b4fa;
      }
    }
  }
}
</style>
